<template>
  <div id="catalogDetail" class="pt-12 mt-6">
    <v-container>
      <v-row class="mb-12">
        <v-col cols="12" class="text-center">
          <h1 class="display-2 mb-4 font-weight-bold">{{ course.name }}</h1>
          <p class="mx-md-12">{{ course.desc.short }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-card class="info-card">
            <v-img height="250" :src="course.previewImg" />
            <v-card-text v-if="courseReg !== null">
              <p>
                Bu eğitime
                {{ courseReg.dateCreated.toDate().toLocaleDateString("tr-TR") }}
                tarihinde kayıt oldunuz.
              </p>
              <v-btn
                color="secondary"
                block
                rounded
                :to="{
                  name: 'course-home',
                  params: { courseUrlCode: course.urlCode }
                }"
                >Eğitimi Aç</v-btn
              >
            </v-card-text>

            <v-card-text v-if="courseReg === null">
              <p class="text-center">Yeni bir şeyler öğrenmeye hazır mısın?</p>
              <v-btn color="secondary" block rounded @click.prevent="register()"
                >Eğitime Kayıt Ol</v-btn
              >
            </v-card-text>

            <v-card-title>Öne Çıkan Özellikler</v-card-title>

            <v-card-text>
              <v-chip-group active-class="purple accent-4 white--text" column>
                <v-chip
                  v-for="(item, i) in course.features"
                  :key="i"
                  class="ma-2 align-self-right"
                  color="primary"
                  outlined
                >
                  <v-icon left>{{ item.icon }}</v-icon>
                  <span>{{ item.title }}</span>
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="pl-md-12">
          <v-expansion-panels popout accordion multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h1 class="font-weight-medium">Eğitim Süreci</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="course.desc.long"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <h1 class="font-weight-medium">Eğitim İçeriği</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Bu eğitim şu modülleri içermektedir:</p>
                <ol>
                  <li v-for="(item, i) in course.modules" :key="i">
                    {{ item }}
                  </li>
                </ol>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <h1 class="font-weight-medium">Öğrenme Çıktıları</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Bu eğitimi tamamladıktan sonra katılımcılar;</p>
                <ul>
                  <li v-for="(item, i) in course.learningOutcomes" :key="i">
                    {{ item }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";

export default {
  components: {
    Confirm
  },
  data() {
    return {
      course: {
        id: "",
        price: 0,
        name: "",
        color: "",
        code: "",
        previewImg: "",
        urlCode: "",
        learningOutcomes: [],
        modules: [],
        desc: {
          short: "",
          long: ""
        },
        rating: {
          value: 0,
          count: 0
        }
      },
      panel: [0, 1, 2],
      courseReg: null
    };
  },
  methods: {
    getCourse() {
      firebase
        .firestore()
        .collection("courses")
        .where("urlCode", "==", this.$route.params.courseUrlCode)
        .limit(1)
        .get()
        .then(qs => {
          const course = qs.docs[0];
          if (course.exists) {
            this.course = course.data();
            this.course.id = course.id;
          }

          this.getCourseReg();
        })
        .catch(error => {
          throw `Eğitim bilgileri alınamadı: ${error}`;
        });
    },
    getCourseReg() {
      firebase
        .firestore()
        .collection("businessCourseRegistrations")
        .where("courseId", "==", this.course.id)
        .where("uid", "==", this.user.id)
        .get()
        .then(regQs => {
          const reg = regQs.docs[0];
          if (reg) {
            this.courseReg = reg.data();
            this.courseReg.id = reg.id;
          }
        })
        .catch(error => {
          throw `Ders kayıt bilgileri alınamadı: ${error}`;
        });
    },
    async register() {
      const confirmation = await this.$refs.confirm.open(
        "Emin misiniz?",
        `<strong>${this.course.name}</strong> eğitimine kayıt olmak istediğinizden emin misiniz?`,
        {
          color: "red"
        }
      );

      if (confirmation) {
        // const newReg = {
        //   uid: this.user.id,
        //   courseId: this.course.id,
        //   dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        //   dateGraduated: null,
        //   progress: 0,
        //   isActive: true,
        //   roleId: "student"
        // };

        // firebase
        //   .firestore()
        //   .collection("businessCourseRegistrations")
        //   .add(newReg)
        //   .then(() => {
        //     this.getCourseReg();
        //   })
        //   .catch(error => {
        //     throw `Eğitim bilgileri alınamadı: ${error}`;
        //   });

        // Veritabanına ekle
        await this.$store.dispatch("lms/addCourseReg", {
          uid: this.user.id,
          courseId: this.course.id
        });

        try {
          this.getCourseReg();
        } catch (err) {
          throw `Eğitim bilgileri alınamadı: ${err.message}`;
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  mounted() {
    this.getCourse();
  }
};
</script>

<style lang="scss" scoped>
#catalogDetail {
  .info-card {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;
  }

  .v-expansion-panel {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px;
    background-color: #f1f1f1;

    &::before {
      box-shadow: none;
      border: none 0 transparent;
    }

    &::after {
      box-shadow: none;
      border: none 0 transparent;
    }
  }
}
</style>
